import baseApi from './baseApi.js';
const administratorApi = {
	// 获取邀请码
	getInviteCode(params,callback){
		baseApi.get('center/conpanyStaff/getInviteCode?phone='+params,null,callback)
	},
	// 加入企业code邀请码
	checkInviteCode(params,callback){
		baseApi.post('center/conpanyStaff/checkInviteCode?code='+params.code,null,callback)
	},
	// 删除企业员工
	delCompanyStaff(params,callback){
		baseApi.post('center/conpanyStaff/delCompanyStaff?staffId='+params,null,callback)
	},
	// 查询员工列表
	getCompanyStaffList(params,callback){
		baseApi.get('center/conpanyStaff/getCompanyStaffList',params,callback)
	},
	// 设置管理员
	setAdmin(params,callback){
		baseApi.post('center/conpanyStaff/setAdmin?staffId='+params,null,callback)
	},
	// 退出企业
	outCompany(params,callback){
		baseApi.post('center/conpanyStaff/outCompany',params,callback)
	},
	// 开通签约功能、
	openSignContract(params,callback){
		baseApi.post('center/signContract/openSignContract',params,callback)
	},
	// 获取模板列表
	getContractTemplateList(params,callback){
		baseApi.get('center/contracttemplate/getContractTemplateList',params,callback)
	},
	// 下载合同
	downloadContract(params,callback){
		baseApi.post('center/signContract/downloadContract?contractCode='+params.contractCode,null,callback)
	},
	// 预览合同
	checkContract(params,callback){
		baseApi.post('center/signContract/checkContract?contractCode='+params.contractCode,null,callback)
	},
	// 查看当前用户是否开通签约功能
	getSignContract(params,callback){
		baseApi.get('center/signContract/getSignContract',params,callback)
	},
	// 获取当前用户线上支付状态
	getPaymentconfigState(params,callback){
		baseApi.get('center/paymentconfig/getPaymentconfigState',params,callback)
	},
	// 开通线上支付功能
	addPanymengconfig(params,callback){
		baseApi.post('center/paymentconfig/addPanymengconfig',params,callback)
	},
	// 上传身份证资料
	setIdCardImage(params,callback){
		baseApi.post('center/paymentconfig/setIdCardImage',params,callback)
	},
	// 获取当前用户线上支付详情
	getPaymentconfigInfo(params,callback){
		baseApi.get('center/paymentconfig/getPaymentconfigInfo',params,callback)
	},
	// 
}
//抛出api 
export default administratorApi