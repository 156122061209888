<template>
  <div>
    <Top></Top>
    <!-- 首页头部 -->
 <div v-if="publicstate" >
    <div class="total">
      <div class="content">
        <div class="left">
          <router-link to="/">
          <img src="../../assets/index/login.png" alt="" /></router-link>
        </div>
        <div class="storea">
           <div class="phonestore">

             <img class="smallimg" src="../../assets/home/onee.jpg" alt=""  @mouseover="showCode" @mouseout="hidenCode" srcset="" >
              <div>手机店铺</div>
             <div id="qrCode"  @mouseover="showCode" @mouseout="hidenCode" ref="qrCodeDiv" v-show="showcode"></div>

         </div>
          <div class="shang">

            <img v-image-preview  :src="CompanyBriefInfo.image"  alt="" />

          </div>

          <router-link
            :to="{
              path: 'StoreHome',
              query: {
                id: searchdetailsdata.companyId,
                name: searchdetailsdata.companyName,
              },
            }"
            class="router"
          >
            <div class="flagshipe">
              {{ searchdetailsdata.companyName }}
            </div>
          </router-link>
          <div class="hong" v-show="CompanyBriefInfo.optimization">
            <img src="../../assets/index/hong.png" />
            <div>尾易优选</div>
          </div>
        </div>
      </div>
    </div>

    <div class="goodmiddle">
      <div class="header" v-if="shopplsme">公告：{{shopplsme}}</div>
       <!-- <div class="scrollstare" v-show="shopplsme">
    <div> 公告:</div><div id="scroll_div" class="fl">
       <div id="scroll_begin">
           <span class="pad_right">{{shopplsme}}</span>
        </div>
        <div id="scroll_end"></div>
    </div>

    </div> -->

      <div class="aaa">
        <div class="line"></div>
        <div class="detail">商品详情</div>
      </div>
      <div class="bigdetail">
        <div>
          <div class="image">

            <img v-image-preview  :src="searchdetailsdata.mainimage" />

          </div>
          <!-- <div class="shenhe">待审核</div> -->
        </div>
        <div class="second">
          <div class="diyi">{{ searchdetailsdata.name }}</div>
          <div class="dier">
            <div v-if="searchdetailsdata.price == 0">
              ￥<span>电议</span
              >
            </div>
            <div v-else>
              ￥<span>{{ searchdetailsdata.price }}</span
            >/吨
            </div>
          </div>
          <nav class="nih">
            <div class="add">
              <div class="first">存放地：</div>
              <div>{{ searchdetailsdata.storageplace }}</div>
            </div>
            <div class="add">
              <div class="first">规格：</div>
              <div>{{ searchdetailsdata.specifications }}</div>
            </div>
            <!-- <div class="add">
              <div class="first">总重：</div>
              <div>{{ searchdetailsdata.totalprice }} 吨</div>
            </div> -->
            <div class="add">
              <div class="first">数量：</div>
              <div>{{ searchdetailsdata.nubmer }} 吨</div>
            </div>
          </nav>
        </div>
        <div class="right">
          <div v-if="!startidear">
            <img
              class="hui"
              @click="hui"
              src="../../assets/home/hui.png"
              alt=""
            />
          </div>
          <div v-else>
            <img
              class="red"
              @click="red"
              src="../../assets/home/red.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="goodbutton">
      <div class="buttfirst">
        <div v-if="imagesList"><i class="el-icon-arrow-left"></i></div>
        <div class="bfit">
          <ul>
            <li>
              <viewer :images="imagesList">
                <img
                  v-for="(item, i) in imagesList"
                  :key="i"
                  :src="item"
                  class="img-thumb active"
                  data-v-78af6416=""
                  data-src=""
                  data-arg="undefined"
                />
              </viewer>
            </li>
          </ul>
        </div>
        <div v-if="imagesList"><i class="el-icon-arrow-right"></i></div>
      </div>
      <div class="buttsecond">
        <div class="add">
          <div class="first">合计：</div>
          <div v-if="searchdetailsdata.price == 0">
            已选<span>{{ searchdetailsdata.nubmer }} </span>吨&nbsp;&nbsp;单价<span>电议</span>
          </div>
          <div v-else>
            已选<span>{{ searchdetailsdata.nubmer }} </span>吨&nbsp;&nbsp;单价<span
          >{{ searchdetailsdata.price }} </span
          >/吨&nbsp;&nbsp;共<span>￥{{ searchdetailsdata.totalprice }}</span>
          </div>
        </div>
        <div class="didia">
          <el-button @click="WannaTalk" type="danger">聊一聊</el-button>
          <Commoditymessage
            @clears="clears"
            :WannaTalkdialog="WannaTalkdialog"
            :CompanyUserList="CompanyUserList"
            @surees="surees"
          ></Commoditymessage>
          <el-button @click="opens" type="danger">合同签署</el-button>
        </div>
        <div class="tishi">
          友情提示：通过尾易网平台下单可以帮您规避交易过程中的潜在风险。让您更省心、更安心
        </div>
      </div>
    </div>
    <div class="butfooter">
      <div>
        <div class="add">
          <div class="first">品线</div>
          <div>{{ searchdetailsdata.productline }}</div>
        </div>
        <div class="add">
          <div class="first">品名</div>
          <div>{{ searchdetailsdata.productname }}</div>
        </div>
        <div class="add">
          <div class="first">材质</div>
          <div>{{ searchdetailsdata.material }}</div>
        </div>

        <div class="add">
          <div class="first">质量等级</div>
          <div>{{ searchdetailsdata.qualitylevel }}</div>
        </div>
        <div class="add">
          <div class="first">规格</div>
          <div>{{ searchdetailsdata.specifications }}</div>
        </div>
        <div class="add">
          <div class="first">仓库</div>
          <div>{{ searchdetailsdata.storageplace }}</div>
        </div>
         <div class="add">
          <div class="first">颜色</div>
          <div>{{ searchdetailsdata.color }}</div>
        </div>
        <div class="add">
          <div class="first">详细描述</div>
          <div>{{ searchdetailsdata.describe ? searchdetailsdata.describe :'无描述' }}</div>
        </div>
      </div>
      <div class="goodssa">
        <div class="add">
          <div class="first">涂油</div>
          <div>{{ searchdetailsdata.oil ? "有涂层" : "无涂层" }}</div>
        </div>
        <div class="add">
          <div class="first">锌层</div>
          <div>{{ searchdetailsdata.zinclayer ? '有' : '无'}}</div>
        </div>
        <div class="add">
          <div class="first">有花无花</div>
          <div>{{ searchdetailsdata.pattern ? '有' : '无'}}</div>
        </div>
        <div class="add">
          <div class="first">表面工艺</div>
          <div>{{ searchdetailsdata.surfaceprocess ? searchdetailsdata.surfaceprocess  : '无'}}</div>
        </div>
        <div class="add">
          <div class="first">质保书</div>
          <div>{{ searchdetailsdata.warranty ? '有' : '无' }}</div>
        </div>
        <div class="add">
          <div class="first">钢厂</div>
          <div>{{ searchdetailsdata.steelmill }}</div>
        </div>

        <div class="add">
          <div class="first">出厂时间</div>
          <div>{{searchdetailsdata.manufacturedate ? $moment(searchdetailsdata.manufacturedate).format("YYYY年MM月DD日 ") : ''}}</div>
        </div>
      </div>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      title=""
      :visible.sync="liability"
      v-if="liability"
      width="30%"
      :before-close="close"
    >
      <div class="popup">

        <div class="mertext1">
          <div id="test1">

          </div>
        </div>
        <div>
          <button @click="issure" class="issure">确认已知晓</button>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      title=""
      :visible.sync="contract"
      v-if="contract"
      width="40%"
      class="qianshudialog"
      :before-close="contractclose"
    >
      <div class="contract">
        <div class="toice">合同签署</div>

        <div class="heada">
          <div class="tanhao">!</div>
          <div class="cont">
            为保障自身权益，交易之前请与商家确认交易信息、物流运输等相关事宜。
          </div>
        </div>
        <div class="mertext1">
          <div>选择合同模板:</div>
          <div class="buttt">
            <div
              v-for="(item, index) in this.contracttemplate"
              :key="index"
              class="buttoa"
            >
              <div class="total" @click="dd(item)">
                <el-radio v-model="ruleForm.templateNo" :label="item.templateno"
                  >{{ " " }}
                </el-radio>
                <div class="wai">
                  <div class="nei">
                    <span>{{ item.templatename }}</span>
                  </div>
                </div>
              </div>

              <!-- <button @click="lookcontract(item)">查看</button>
              <el-dialog
                :visible.sync="pdfVisible"
                :close-on-click-modal="false"
                :append-to-body="true"
              > -->
              <el-link
                target="_blank"
                :href="item.templateurl"
                :underline="false"
                type="primary"
                style="margin-left: 170px"
              >
                查看
              </el-link>
              <!-- <iframe style="border: none" :src="reportpdfUrl"></iframe> -->
              <!-- <pdf :src="reportpdfUrl"></pdf> -->
              <!-- </el-dialog> -->
            </div>
          </div>
        </div>
        <div>
          <el-form
            :model="ruleForm"
            ref="ruleFormname"
            :label-position="labelPosition"
            :rules="rules"
            class="ruleForminput"
          >
            <el-form-item label="交货地址" prop="deliveryaddress">
              <el-input v-model="ruleForm.deliveryaddress" placeholder="请输入您想要交货的详细地址"></el-input>
            </el-form-item>
            <el-form-item label="交货方式" prop="deliverymethod">
              <el-input v-model="ruleForm.deliverymethod" placeholder="例如：自提"></el-input>
            </el-form-item>
            <el-form-item label="计量方式" prop="measmethod">
              <el-input v-model="ruleForm.measmethod" placeholder="例如：5.0吨"></el-input>
            </el-form-item>
            <el-form-item label="运输方式" prop="transportmethod">
              <el-input v-model="ruleForm.transportmethod" placeholder="例如：汽运"></el-input>
            </el-form-item>
            <!-- <el-form-item label="税金（元）" prop="taxrate">
              <el-input
                v-model="ruleForm.taxrate"
                placeholder="例如：500元"
                onkeyup="value=value.replace(/[^\d]/g,'')"
              ></el-input>
            </el-form-item> -->
            <el-form-item label="备注" prop="remarks">
              <el-input placeholder="请填写您想要的填写的备注信息，没有可以不填" v-model="ruleForm.remarks"></el-input>
            </el-form-item>

            <button @click.prevent="sendcontract(ruleForm)" class="issure">
              确认发送合同
            </button>
          </el-form>
        </div>
      </div>
    </el-dialog>
    <!-- <el-dialog
      :close-on-click-modal="false"
      title=""
      :visible.sync="order"
      v-if="order"
      width="30%"
      :before-close="orderclose"
    >
      <div class="order">
        <div class="toice">提示</div>
        <div class="mertext1">
          已有进行中的订单，请点击下方确认按钮前往订单查看。
        </div>
        <div class="bbb">
          <button @click="orderclose" class="quxiao">取消</button>
          <button @click="orderissure" class="issure">前往交易记录</button>
        </div>
      </div>
    </el-dialog> -->
</div>
 <div class="publicstatecss" v-else>
       商品不存在
 </div>
    <Footer></Footer>
  </div>
</template>
<script>
import pdf from "vue-pdf";
import userApi from "../../api/updataApi";
import { Message,MessageBox } from "element-ui";
import Top from "@/components/login/Top.vue";
import Footer from "@/components/login/Footer.vue";
import goodsApi from "../../api/goodsApi";
import Commoditymessage from "../../components/chat/customer.vue";
import administratorApi from "../../api/administrator";
import loginApi from "../../api/loginApi";
import tradingApi from "../../api/tradingApi";
import companyApi from "../../api/companyApi";
import basecfg from "../../utils/applocationCfg"
import imApi from "../../api/imApi";
import { mapState, mapActions } from "vuex";
import MessageWannaTalk from "../../components/chat/message.vue";
import QRCode from 'qrcodejs2';
import { log } from "../../utils/EMedia_x1v1_3.4.1";
export default {
  data() {
    return {
        baseApi:basecfg.getUrl(),
       showcode:false,
      // 公告
      shopplsme:"",
      //  企业信息
      CompanyBriefInfo: "",
      activeKey: "contact",
      broken: false,

      pdfVisible: false,
      // pdf地址
      reportpdfUrl: "",
      labelPosition: "right",
      // 确认合同
      ruleForm: {
        deliveryaddress: "",
        deliverymethod: "",
        goodsId: this.$route.query.id,
        measmethod: "",
        remarks: "",
        taxrate: null,
        templateNo: "",
        transportmethod: "",
      },
      templatemo: "",
      //  合同模板类表
      contracttemplate: [],
      // 确认合同规格
      rules: {
        deliveryaddress: [
          { required: true, message: "请输入交货地址", trigger: "blur" },
        ],
        deliverymethod: [
          { required: true, message: "请输入交货方式", trigger: "blur" },
        ],
        measmethod: [
          { required: true, message: "请输入计量方式", trigger: "blur" },
        ],

        taxrate: [{ required: true, message: "请输入税金额", trigger: "blur" }],
        transportmethod: [
          { required: true, message: "请输入运输方式", trigger: "blur" },
        ],
      },
      CompanyUserList: [],
      radio1: "1",
      WannaTalkdialog: false,
      liability: false,
      contract: false,
      confirmed: false,
      publicstate:true,
      order: false,
      id: this.$route.query.id,
      searchdetailsdata: "",
      searchgoodsinput: "",
      imagesList: [],
      comyuser: {
        image: undefined,
        name: "f9b5fc1dcdac4c16bd6182d0a77bcdee",
        nicknamer: undefined,
      },
      options: [
        {
          value: "1",
          label: "上海宝钢集团旗舰店",
        },
      ],
      value: "1",
      num: 1,
      a: "el-icon-arrow-down",
      startidear: false,
    };
  },
  components: {
    Top,
    Footer,
    Commoditymessage,
    MessageWannaTalk,
    pdf,
  },
  mounted(){



    this.searchdetails();
     this.timer = new Date().getTime();
   this.ischeckLogin()
    this.getProclamationInfo()


    setTimeout(()=>{
   this.bindQRCode();

},1000)
 let islogin = false;
         let isLogin = localStorage.getItem("Login_data");
      if (!isLogin) {
        return false;
      } else {
        loginApi.checkLogin("", (res) => {

          if (res.data.code == 200) {
            if (!res.data.result) {

              islogin = false;
            }
            else{

              islogin = true;
            }
          }
        });
      };
      setTimeout(()=>{
          if(islogin){
            this.getCollectRecordState();
    }
      },100)
  },
  methods: {
      showCode(){
       this.showcode = true

    },
    hidenCode(){
        this.showcode = false

    },
    // 二维码
      bindQRCode: function () {
        new QRCode(this.$refs.qrCodeDiv, {
          text: this.baseApi + "/h5/#/pages/goods/storedetail/storedetail?companyId=" + this.companyId + "&cy=" +this.searchdetailsdata.companyName,
          // text: 'http://172.168.0.105:8080/iot/app/download',
          width: 150,
          height: 150,
          colorDark: "#333333", //二维码颜色
          colorLight: "#ffffff", //二维码背景色
          correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
        })

    },
        // 滚动
     startScroll(){
         //文字横向滚动
    function ScrollImgLeft(){
        var speed=50;
        var MyMar = null;
        var scroll_begin = document.getElementById("scroll_begin");
        var scroll_end = document.getElementById("scroll_end");
        var scroll_div = document.getElementById("scroll_div");
        scroll_end.innerHTML=scroll_begin.innerHTML;
        function Marquee(){
            if(scroll_end.offsetWidth-scroll_div.scrollLeft<=0)
                scroll_div.scrollLeft-=scroll_begin.offsetWidth;
            else
                scroll_div.scrollLeft++;
        }
        MyMar=setInterval(Marquee,speed);
        scroll_div.onmouseover = function(){
            clearInterval(MyMar);
        }
        scroll_div.onmouseout = function(){
            MyMar = setInterval(Marquee,speed);
        }
    }
    ScrollImgLeft();
     },

    // 获取公告
    getProclamationInfo(){
      loginApi.getProclamationInfo("goods",res=>{

        if(res.data.code==200){
            this.shopplsme = res.data.result.value
            //  setTimeout(() => {
            //   this.startScroll();
            // }, 2000);
        }

      })
    },
    // 获取当前商品收藏状态
    getCollectRecordState() {
      goodsApi.getCollectRecordState({ sourceId: this.id }, (res) => {

        if (res.data.code == 200) {
          this.startidear = res.data.result;
        }
      });
    },
    // 查看合同pdf
    lookcontract(item) {

    },
    dd(e) {
      this.ruleForm.templateNo = e.templateno;
    },

    clears(bool) {
      this.WannaTalkdialog = bool;
    },
    surees(bool) {
      this.WannaTalkdialog = bool;
    },
    // 聊一聊
    WannaTalk() {
      var islogin = this.checkLogin();

      if (!islogin) return;
      var isordinary = localStorage.getItem('userInfostate')

      if(isordinary=="ordinary"){
                imApi.getCompanyUserList({ companyId: this.companyId }, (res) => {
        if (res.data.code == 200) {
          this.CompanyUserList = res.data.result;
          this.WannaTalkdialog = true;
        }
      });
      }
      else{
 //获取企业id
      companyApi.getCurrCompanyInfo("",res=>{

           if(this.companyId==res.data.result.uuid){
              Message.error("不能和自己企业聊天")
           }
           else{
               imApi.getCompanyUserList({ companyId: this.companyId }, (res) => {
        if (res.data.code == 200) {
          this.CompanyUserList = res.data.result;
          this.WannaTalkdialog = true;
        }
      });
           }
      })
      }


    },
    ischeckLogin(){
         let isLogin = localStorage.getItem("Login_data");
      if (!isLogin) {
        return false;
      } else {
        loginApi.checkLogin("", (res) => {

          if (res.data.code == 200) {
            if (!res.data.result) {

              return false;
            }
            else{

              return true;
            }
          }
        });
      }
    },
    checkLogin() {
      let isLogin = localStorage.getItem("Login_data");
      if (!isLogin) {
        Message.error("请先登录");
        return false;
      } else {
        loginApi.checkLogin("", (res) => {
          if (res.data.code == 200) {
            if (!res.data.result) {
              Message.error("请先登录");
              return false;
            }
          }
        });
      }
      if (WebIM.isLogin) return true;
      imApi.getCurrUser("", (res) => {
        if (res.data.code == 200) {
          var options = {
            user: res.data.result.username,
            pwd: res.data.result.password,
            appKey: WebIM.config.appkey,
            apiUrl: WebIM.config.Host,
          };

          WebIM.conn.open(options);
          return true;
        } else if (res.data.code == 401) {
          Message.error("请先进行个人认证");
          return false;
        }
      });
    },
    // 得到企业客服对话框
    // messageWannaopen() {
    //   // this.WannaTalkdialog = false;
    //  this.select(this.comyuser);
    // },
    // 搜索获取商品详情页数据
    searchdetails() {
      goodsApi.searchdetails({ id: this.id }, (res) => {

        this.publicstate = res.data.result
        if (res.data.code != 200) {
          Message.error(res.data.message);
        } else {
          this.searchdetailsdata = res.data.result;

          this.companyId = this.searchdetailsdata.companyId;
          this.imagesList = res.data.result.image;
          // 获取当前企业信息
          tradingApi.getCompanyBriefInfo(
            { companyId: this.companyId },
            (res) => {

              this.CompanyBriefInfo = res.data.result;
            }
          );
        }
      });
    },
    handleChange(value) {

    },
    hui() {
      // 添加收藏
      let isLogin = localStorage.getItem("Login_data");
      if (isLogin) {
        loginApi.checkLogin("", (res) => {
          if (res.data.code == 200) {
            if (!res.data.result) {
              localStorage.removeItem("Login_data");
            }
          }
        });
        goodsApi.addCollectRecord({sourceId: this.id }, (res) => {
          if (res.data.code == 200) {
            Message.success("收藏成功");
            this.startidear = true;
          } else {
            Message.error(res.data.message);
          }

        });
      } else {
        Message.error("请先登录");
      }
    },
    red() {
      // 取消收藏
      goodsApi.delCollectRecord({sourceId: this.id }, (res) => {
        if (res.data.code == 200) {
          Message.success("取消收藏");
          this.startidear = false;
        } else {
          Message.error(res.data.message);
        }
      });
    },
    // 确认合同
    opens() {
      let isLogin = localStorage.getItem("Login_data");
      if (isLogin) {
        loginApi.checkLogin("", (res) => {
          if (res.data.code == 200) {
            if (!res.data.result) {
              localStorage.removeItem("Login_data");
            }
          }
        });
         MessageBox.confirm('确认生成合同吗?', '生成合同', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        .then((_) => {
              this.contract = !this.contract;
      administratorApi.getContractTemplateList("", (res) => {
        this.contracttemplate = res.data.result;
      });
        })
        .catch((_) => {
           Message({
            type: 'info',
            message: '已取消确认'
          });
        });
      } else {
        Message.error("请先登录");
      }
    },
    close() {
      this.liability = !this.liability;
    },
    contractclose() {
      this.contract = !this.contract;
      this.ruleForm.deliveryaddress = ""
      this.ruleForm.deliverymethod = ""
      this.ruleForm.measmethod = ""
      this.ruleForm.remarks = ""
      this.ruleForm.taxrate = null
      this.ruleForm.templateNo = ""
      this.ruleForm.transportmethod = ""
    },
    // 确认已知晓
    issure() {
      this.liability = !this.liability;
      this.contract = !this.contract;
      administratorApi.getContractTemplateList("", (res) => {
        this.contracttemplate = res.data.result;
      });
    },
    // 确认发送合同
    sendcontract(Formname) {
      if (!this.ruleForm.templateNo) {
        return Message.error("请选择合同模板");
      } else {

        this.$refs.ruleFormname.validate((valid) => {
          if (valid) {
         loginApi.checkLogin("", (res) => {
          if (res.data.code == 200) {
            if (!res.data.result) {
               Message.error("请先登录");
            }
            else{
                Formname.taxrate = parseInt(Formname.taxrate);
            tradingApi.createOrder(Formname, (res) => {
              if (res.data.code == 200) {
                Message.success("合同发送成功");
                  this.$uweb.trackEvent("签约行为","点击","生成合同")
                this.contract = !this.contract;
                this.order = !this.order;
              } else {
                 Message.error(res.data.message);
              }
            });
            }
          }
        });

          }
        });
      }
    },
    // 取消 确认发送合同
    confirmedclose() {
      this.confirmed = !this.confirmed;
    },
    // 前往交易记录
    issurechange() {
      this.confirmed = !this.confirmed;
      this.order = !this.order;
    },

    orderclose() {
      this.order = !this.order;
      this.ruleForm = {};
    },
    orderissure() {
      this.order = !this.order;
      this.$router.push("/home/transaction");
      this.ruleForm = {};
    },
    ...mapActions([
      "onLogout",
      "onGetFirendBlack",
      "initChatState",
      "updateConfr",
      "setCallStatus",
      "hangup",
      "cancelCall",
      "onGetContactUserList",
    ]),

    onCollapse(collapsed, type) {
      if (type != "responsive") {
        this.$data.collapsed = true;
      } else {
        this.$data.collapsed = false;
      }
    },
    onBreakpoint(broken) {
      this.$data.broken = broken;
    },
    show_add_member_modal() {
      this.$refs.addAvMembertModal.show();
    },
    hideUserList() {
      this.$data.collapsed = true;
    },
    showUserList() {
      this.$data.collapsed = false;
    },
  },
  watch: {},
  computed: {
    userDetail() {
      return this.$store.state.home.userDetail;
    },
    chatList() {
      return this.$store.state.chat.msgList;
    },
    onSetCallStatus() {
      return this.$store.state.agora.callStatus;
    },
  },
};
</script>
<style lang='scss' scope>
#qrCode{
  margin: 20px;
  padding: 10px;
  background-color: white;
  width: 150px;
  height: 150px;
     position: absolute;
     top: 30px;
     left: -36px;
  }
.phonestore{
  width:200px ;
  height: 50px;
  margin-left: 20px;
  text-align: center;
  color: rgb(155, 155, 155);
   position: relative;
  // border: 1px solid red;
  .smallimg{
    width: 30px;
    height: 30px;
    margin-top: 3px;

  }
}
.publicstatecss{
  // border: 1px solid red;
  text-align: center;
  color: #e94f55;
  padding-top: 200px;
  font-size: 20px;
  min-height: 520px;
}
 .scrollstare{
    width:98%;
    display: flex;
  justify-content: space-between;
  align-items: center;
      margin: 0 auto;
 }
 #scroll_div {
            height:30px;
            //  border: 1px solid red;
            line-height: 30px;
            overflow: hidden;
            white-space: nowrap;
            width:96%;
            background-color: #fff;
            color:  rgb(233,79,85);

        }
        #scroll_begin,#scroll_end {
            display: inline;
        }
::v-deep .el-dialog {
  min-width: 300px !important;
}
iframe {
  height: 700px;
}

::v-deep.el-dialog {
  min-width: 530px;
}
.ruleForminput {
  // border: 1px solid red;
  width: 80%;
  margin: 10px auto;

}
.ruleForminput .el-input {
  width: 79%;
  float: right;
}
.router {
  text-decoration: none;
}
.confirmed,
.order {
  .color {
    color: #999999;
  }

  .toice {
    text-align: center;
    color: #3b4144;
  }

  .mertext1 {
    width: 500px;
    border-radius: 10px;
    margin-top: 10px;
    margin-left: 65px;
    color: #999999;
    font-size: 14px;

  }

  .bbb {
    display: flex;

    .quxiao {
      width: 100px;
      height: 40px;
      font-size: 15px;
      color: #dd524d;
      background-color: white;
      margin-left: 100px;
      margin-top: 30px;
      margin-bottom: 20px;
      border-radius: 20px;
      border: 1px solid #dd524d;
    }

    .issure {
      width: 160px;
      height: 40px;
      font-size: 15px;
      color: #dd524d;
      background-color: white;

      margin-top: 30px;
      margin-bottom: 20px;
      border-radius: 20px;
      border: 1px solid #dd524d;
    }

    .quxiao:hover,
    .issure:hover {
      color: white;
      background-color: #dd524d;
    }
  }
}
.storea {

  display: flex;
  // border: 1px solid red;
  align-items: center;
  .hong {
    //  width: 90px !important;
    line-height: 32px !important;
    display: flex;
    color: #e94f55 !important;
    margin-left: 21px !important;
    img {
      width: 14px !important;
      height: 14px !important;
      position: relative;
      top: 9px !important;
    }
    div {
      margin-left: 6px !important;
    }
  }
  .shang {
    img {
      width: 50px;
      height: 40px;
      margin-right: 10px;
    }
  }
  .flagshipe {
    color: #333333;
    font-size: 14px;
    line-height: 32px;
    // margin-left: -15px;
  }
}

.contract {
  .heada {
    display: flex;
    margin-left: 65px;
    margin-top: 5px;
    width: 400px;
    .tanhao {
      color: white;
      background-color: #ff8c00;
      width: 15px;
      height: 15px;
      line-height: 15px;
      text-align: center;
      border-radius: 50%;
      font-size: 10px;
      margin-top: 3px;
    }

    .cont {
      color: #999999;
      font-size: 13px;
      font-weight: 400;
      height: 30px;
      margin-left: 3px;
    }
  }

  .color {
    color: #999999;
  }

  .merimage {
    width: 35px;
    height: 35px;
    position: relative;
    top: -13px;
    left: 322px;
  }

  .toice {
    text-align: center;
    color: #3b4144;
  }

  .issure {
    width: 160px;
    height: 40px;
    font-size: 15px;
    color: white;
    background-color: #dd524d;
    margin-left: 120px;
    margin-top: 30px;
    margin-bottom: 20px;
    border-radius: 20px;
    border: 0px solid;
  }

  .mertext1 {
    width: 500px;
    border-radius: 10px;
    margin-top: 10px;
    margin-left: 65px;
    color: #999999;
    font-size: 14px;

    .buttt {
      .buttoa {
        a {
          margin-left: 18px !important;
        }
      }

      display: flex;
      flex-wrap: wrap;
      width: 400px;
      // border: 1px solid #E94F55;
      .el-link {
        border: 1px solid #e94f55;
        border-radius: 2px;
        background-color: white;
        height: 22px;
        width: 75px;
        text-align: center;
        color: #e94f55;
        line-height: 20px;
        font-size: 12px;
        margin-left: 15px;
        margin-top: 10px;
      }

      button:hover {
        background-color: #e94f55;
        color: white;
      }
    }

    .total {
      margin-top: 10px;
      width: 77px;
      height: 104px;
      border-radius: 3px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      margin-left: 15px;

      .wai {
        height: 82px;
        width: 77px;
        background-color: rgba(0, 0, 0, 0.1);
      }

      .nei {
        height: 78px;
        margin: 0px auto;
        width: 68px;
        background-color: white;
        position: relative;
        top: 10px;
      }

      .add {
        text-align: center;
        font-size: 12px;
        height: 22px;
        line-height: 22px;
      }
    }
  }
}

.popup {
  .toice {
    text-align: center;
    color: #3b4144;
  }

  .mertext1 {
    width: 90%;
    border-radius: 10px;
    margin-top: 10px;
   margin: 0 auto;
    color: #999999;
    font-size: 14px;
  }
  .issure {
    width: 160px;
    height: 40px;
    font-size: 15px;
    color: white;
    background-color: #dd524d;
    margin-left: 170px;
    margin-top: 30px;
    margin-bottom: 20px;
    border-radius: 20px;
    border: 0px solid;
  }
}

.total {
  border-bottom: 1px solid #e95157;
}
.content {
  width: 60%;
  height: 99px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 740px;
  .left {
    box-sizing: border-box;
    width: 200px;
    // padding: 0px 13px 0px 14px;
    //  border: 1px solid red;
    img {
      width: 140px;
      // height: 70px;
      vertical-align: middle;
      //   padding-left: 30px;
      // border: 1px solid red;
    }
  }
  .center {
    width: 700px;
    box-sizing: content-box;
    margin-left: 46px !important;
    height: 40px;
    border-radius: 3px;
    border: 2px solid #e94f55;
    display: flex;
    justify-content: start;
    flex-wrap: nowrap;
    .el-input {
      border: none;
    }

    .el-button--danger {
      border-radius: 0px;
      font-size: 16px;
      color: #e94f55ff;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
.goodmiddle {
  width: 60%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  min-width: 740px;
  .nih {
    margin-top: 20px;
  }
  .header {
    width: 99%;
    background: #e94f55;
    height: 25px;
    line-height: 25px;
    font-weight: 800;
    color: white;
    padding-left: 10px;
    font-size: 16px;
    margin-top: 15px;
  }
  .aaa {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    .line {
      width: 3px;
      height: 18px;
      background: #e94f55;
      border-radius: 2px;
      margin-top: 6px;
    }
    .detail {
      width: 128px;
      height: 26px;
      font-size: 20px;
      font-weight: 600;
      color: #e94f55;
      margin-left: 20px;
    }
  }
  .bigdetail {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    .image {
      img{
         width: 235px;
      height: 235px;
      background: #333333;
      }

    }
    .shenhe {
      color: white;
      width: 235px;
      height: 25px;
      background: rgba(0, 0, 0, 0.6);
      margin-top: -28px !important;
      text-align: center;
      line-height: 24px;
    }
  }
  .second {
    width: 50%;
    margin-left: 20px;
    .diyi {
      color: rgba(0, 0, 0, 0.87);
      font-size: 20px;
      font-weight: bold;
    }
    .dier {
      margin-top: 20px;
      .nowmoney {
        font-size: 10px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.56);
      }
    }
    .dier div:nth-of-type(1) {
      color: #e94f55;
      font-weight: bold;
      font-size: 16px;
    }
    .dier div:nth-of-type(1) span {
      font-size: 20px;
      font-weight: bold;
    }
    .add {
      display: flex;
      flex-direction: row;
      height: 25px;
      margin-bottom: 10px;
      // border: 1px solid red;
      .first {
        width: 86px;
        font-size: 14px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.56);
      }
    }
    .add div:nth-of-type(2) {
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.87);
    }
  }

  .right {
    width: 25%;
    .number {
      font-size: 16px;
      color: #999999;
      margin-top: 170px;
      float: right;
    }
    .hui,
    .red {
      width: 25px;
      height: 25px;
      float: right;
    }
  }
}
.goodbutton {
  width: 60%;

  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  min-width: 740px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 30px;
  .add {
    display: flex;

    // flex-direction: row;
    height: 30px;
    // margin-bottom: 10px;
    //   line-height: 80px;
    .first {
      width: 86px;
      font-size: 14px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.56);
    }
  }
  .add div:nth-of-type(2) {
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
  }
  .add div:nth-of-type(2) span {
    color: #e94f55;
  }
  .buttfirst {
    width: 250px;
    display: flex;
    flex-direction: row;
    .el-icon-arrow-left,
    .el-icon-arrow-right {
      line-height: 70px;
      font-weight: bolder;
    }
    .bfit img {
      width: 70px;
      height: 70px;
      margin-left: 5px;
    }
    ul {
      width: 215px;
      display: flex;
      flex-direction: row;
    }
    ul li {
      list-style: none;
    }
  }
  .buttsecond {
    width: 896px;
    margin-left: 20px;
  }
  .didia {
    margin-top: 10px;
    .el-button {
      line-height: 8px;
      width: 147px;
      height: 35px;
      border-radius: 5px;

      margin-right: 10px;
    }
  }
  .tishi {
    font-size: 16px !important;
    margin-top: 10px;
    color: rgba(0, 0, 0, 0.56);
  }
}
.butfooter {
  // border: 1px solid red;
  width: 60%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  min-width: 740px;
  // margin-top: 20px;
  display: flex;
  justify-content: space-between;
  // flex-direction: row;
  padding-left: 30px;
  margin-bottom: 30px;
  .add {
    display: flex;
    flex-direction: row;
    height: 30px;
    line-height: 80px;
    margin-bottom: 10px;
    .first {
      width: 100px;
      font-size: 14px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.56);
    }
  }
  .add div:nth-of-type(2) {
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
  }
  .goodssa {
    width: 600px;
    // height: 100%;
    // border: 1px solid red;
  }
}
</style>
