import baseApi from './baseApi.js';
const userApi = {
   // 修改用户信息
 updateInfo(params,callback){
  baseApi.post("center/userinfo/updateUserInfo",params,callback)
 },
//  修改手机号
updatePhone(params,callback){
   baseApi.post("center/userinfo/updatePhone?phone="+params.phone+"&code="+params.code,null,callback)
},
// 修改手机号验证码
sendUpPhoneCode(params,callback){
   baseApi.post("center/userinfo/sendUpPhoneCode?phone="+params.phone,null,callback)
},
// 修改密码
updatePassword(params,callback){
   baseApi.post("center/userinfo/updatePassword?oldPwd="+params.oldPwd+"&newPwd="+params.newPwd,null,callback)
},
//  更改用户头像
 updateImage(params,callback){
  baseApi.postImage('center/userinfo/updateimage',params,callback)
 },
 //  获取当前角色信息
 getCurrUserRole(params,callback){
   baseApi.get('center/userinfo/getCurrUserRole',params,callback)
},
//  获取当前用户信息
 getcurruserinfo(params,callback){
    baseApi.get('center/userinfo/getcurruserinfo',params,callback)
 },
//  实名认证个人信息
addUseridentify(params,callback){
   baseApi.post("center/userIdentity/addUseridentify?name="+params.name+"&idCard="+params.idCard+"&mobile="+params.mobile,null,callback)
},
// 获取当前用户认证状态
getUseridentityStates(params,callback){
   baseApi.get('center/userIdentity/getUseridentityStates',params,callback)
},
}

//抛出api 
export default userApi;
